var site = site || {};
var generic = generic || {};
var disableFB = Drupal.settings.globals_variables.disable_fb_share || 0;

//Fix for IOS4 Menu navigation and Sign in popup scrolling
var navScroll, popupScroll;
$(window).on('load', function() {
    if (generic.env.isIOS4) {
      navScroll = new IScroll('.global-header-navigation-elc-nodeblock' ,
                              { mouseWheel: true, click: true, tap: true, }
                             );
      popupScroll = new IScroll('#colorbox',
                                { mouseWheel: true, click: true, tap: true, }
                               );
    }
});

(function($) {
  var getShortUrl = function(urlIn, callback) {
    var accessToken = '';
    var apiUrl = '';
    // add swatch info
    var skuBaseId = $('.swatch--selected.selected').attr('data-sku-base-id');
    // clean up urlIn
    urlIn = urlIn.replace(/#.*/, '');

    if (skuBaseId) {
      urlIn += '#' + skuBaseId;
    }
    accessToken = 'f59213cc485198f3cba5dbc55e75bd6891f77ece';
    apiUrl = 'https://api-ssl.bitly.com/v4/shorten';

    $.ajax({
      beforeSend: function (request) {
        request.setRequestHeader('Authorization', 'Bearer ' + accessToken);
        request.setRequestHeader('Content-Type', 'application/json');
      },
      dataType: 'json',
      type: 'POST',
      url: apiUrl,
      data: JSON.stringify(
        {
          long_url: urlIn,
          domain: 'bit.ly'
        }
      ),
      success: function (response) {
        if (callback) {
          callback(response.link);
        }
      }
    });
  };

  var openSocialMedia = function(win, newUrl) {
    if (win) {
      win.location.href = newUrl;
      win.focus();
    }
  };

  var applySocialMediaOffer = function(offerCode) {
    var signedIn = site.userInfoCookie.getValue('signed_in');
    var isLoyaltyMember = site.userInfoCookie.getValue('is_loyalty_member');

    // if the user is signed in and a loyalty member apply the social media offer
    if (offerCode && parseInt(signedIn) && parseInt(isLoyaltyMember)) {
      var paramObj = {'offer_code': offerCode, 'do_not_defer_messages': 1};

      generic.jsonrpc.fetch({
        method: 'offers.apply',
        params: [paramObj]
      });
    }
  };

  Drupal.behaviors.ELBMobile = {
    attach: function(context, settings) {
      $('.spp-product__share').click(function() {
        var url = document.documentURI;
        var title = document.title;
        var shareCopyLine1 = site.translations.product.social_share_copy_1 || 'What\'s all the buzz about?';
        var shareCopyLine2 = site.translations.product.social_share_copy_2 || 'Check out ::product:: from @Esteelauder';
        var shareCopyLine3 = '';
        var facebook_url = '';
        var pinterest_url = '';
        var img = '';
        var socialHTML = '';
        var facebookLink = '';
        var isLoyaltyMember = parseInt(site.userInfoCookie.getValue('is_loyalty_member'));

        if (page_data && page_data['catalog-spp'] && Array.isArray(page_data['catalog-spp']['products']) && page_data['catalog-spp']['products'][0]) {
          title = page_data['catalog-spp']['products'][0]['PROD_RGN_NAME'];
        }
        shareCopyLine2 = shareCopyLine2.replace('::product::', title);

        if (isLoyaltyMember) {
          shareCopyLine3 = site.translations.product.social_share_loyalty_copy || '(I\'m an E-Lister and may receive loyalty points for sharing.)';
          shareCopyLine3 = '%0A' + encodeURI(shareCopyLine3);
        }

        facebook_url = 'http://www.facebook.com/sharer.php?u=' + encodeURI(url) + '&quote=' + encodeURI(shareCopyLine1) + '%0A' + encodeURI(shareCopyLine2) + shareCopyLine3;

        img = $('#main img').first().attr('src');
        pinterest_url = 'http://pinterest.com/pin/create/button/?url=' + encodeURI(url) + '&media=' + encodeURI(window.location.protocol) + '//' + encodeURI(window.location.host) + img + '&description=' + encodeURI(shareCopyLine1) + '%0A' + encodeURI(shareCopyLine2) + shareCopyLine3;

        facebookLink = !disableFB ? '<li><a class="social-share__link facebook" href="' + facebook_url + '" target="_blank">Facebook</a></li>' : '';
        socialHTML = '<div class="social-share"> <ul>' + facebookLink + '<li><a class="social-share__link pinterest" href="' + pinterest_url + '" target="_blank">Pinterest</a></li> </ul> </div>';
        $.colorbox({
          html: socialHTML,
          className: 'colorbox--social-share',
          width: '100%',
          height: '100%',
          onComplete: function(html) {
            if (Drupal.settings.lyl_social_ocodes_enabled && Drupal.settings.lyl_social_ocodes) {
              var ocodes = Drupal.settings.lyl_social_ocodes || {};
              $('.colorbox--social-share .facebook').attr('offer_code', ocodes.facebook);
              $('.colorbox--social-share .pinterest').attr('offer_code', ocodes.pinterest);
            }
          }
        });

        // pinterest
        $('.social-share .social-share__link.pinterest').on('click tap', function(e) {
          e.preventDefault();
          var winPinterest = window.open('', '_blank');
          getShortUrl(document.location.href, function(result) {
            var url = result;
            var img = $('#main img').first().attr('src');
            var pinterest_url = 'http://pinterest.com/pin/create/button/?url=' + encodeURI(url) + '&media=' + encodeURI(window.location.protocol) + '//' + encodeURI(window.location.host) + img + '&description=' + encodeURI(shareCopyLine1) + '%0A' + encodeURI(shareCopyLine2) + shareCopyLine3;
            openSocialMedia(winPinterest, pinterest_url);
          });
          applySocialMediaOffer($(this).attr('offer_code'));
        });

        // facebook
        $('.social-share .social-share__link.facebook').on('click tap', function(e) {
          e.preventDefault();
          var winFacebook = window.open('', '_blank');

          getShortUrl(document.location.href, function () {
            var urlSku = document.documentURI;

            facebook_url = 'http://www.facebook.com/sharer.php?u=' + encodeURI(urlSku) + '&quote=' + encodeURI(shareCopyLine1) + '%0A' + encodeURI(shareCopyLine2) + shareCopyLine3;

            openSocialMedia(winFacebook, facebook_url);
          });
          applySocialMediaOffer($(this).attr('offer_code'));
        });

        // twitter
        $('.social-share .social-share__link.twitter').on('click tap', function(e) {
          e.preventDefault();
          var winTwitter = window.open('', '_blank');
          getShortUrl(document.location.href, function(result) {
            var url = result;
            var twitter_url = 'http://twitter.com/intent/tweet?url=' + encodeURI(url) + '&text=' + encodeURI(shareCopyLine2) + shareCopyLine3;
            openSocialMedia(winTwitter, twitter_url);
          });
          applySocialMediaOffer($(this).attr('offer_code'));
        });

        return false;
      });

      // Temporary functionality for node 26026
      $('.node-26026 .node-29050 .hero-tout-mobile-mobile__content').click(function() {
        window.location.href = '/product/681/36143/Product-Catalog/Skincare/New-Dimension/Shape-Fill-Expert-Serum';
      });
      $('.node-26026').find('.mpp__product-sort-container').each(function() {
        $(this).hide();
      });

      // Temporary functionality for node 29190
      $('.node-29190 .node-29186 .hero-tout-mobile-mobile__content').click(function() {
        window.location.href = '/product/681/36143/Product-Catalog/Skincare/New-Dimension/Shape-Fill-Expert-Serum';
      });
      $('.node-29190').find('.mpp__product-sort-container').each(function() {
        $(this).hide();
      });

      // Temporary functionality for node 29178
      $('.node-29178 .hero-tout-mobile-mobile__content').click(function() {
        window.location.href = '/product/681/36146/Product-Catalog/Skincare/New-Dimension/Shape-Sculpt-Face-Kit';
      });
      // Temporary functionality for node 29184
      $('.node-29184 .hero-tout-mobile-mobile__content').click(function() {
        window.location.href = '/product/681/36146/Product-Catalog/Skincare/New-Dimension/Shape-Sculpt-Face-Kit';
      });

      $('.elc-user-state-anonymous .menu-reference--loyalty').click(function(event) {
        event.preventDefault();
        window.location.href = '/loyalty_ldg';
      });
    }
  };

  Drupal.behaviors.ELBIOS4StickyFooter = {
    attach: function(context, settings) {
      if (generic.env.isIOS4) {
        var $stickyFooter = $('.page-sticky-footer');
        var $stickyHeader = $('.page-header');
        var $footer = $('.page-footer');
        $stickyFooter.addClass('mobile_sticky');
        $stickyFooter.css('top', window.innerHeight + window.scrollY - 30 + 'px');
        $footer.css('position', 'static');
        $stickyHeader.addClass('mobile_sticky');

        $(document).on('touchstart', function(e) {
          // to avoid hiding the footer if it is node 'touched' by the user
          if (!$(e.target).parents('.page-sticky-footer').length > 0) {
            $stickyFooter.hide();
          }
          // to avoid hiding the header if it is node 'touched' by the user
          if (!$(e.target).parents('.page-header').length > 0) {
            $stickyHeader.hide();
          }
        });

        $(document).on('touchend', function() {
          _.defer(function() {
            // handles waiting until snapback scrolling on mobile devices has finished
            $stickyFooter.show();
            $stickyHeader.show();
          });
        });

        $(document).on('scroll', function(e) {
          $stickyFooter.css('top', window.innerHeight + window.scrollY - 30 + 'px');
          $stickyHeader.css('top', $(window).scrollTop() + 'px');
        });
      }
    }
  };

  Drupal.behaviors.ELBMobileStickyFooter = {
    attach: function(context, settings) {
      var $stickyFooter = $('.page-sticky-footer', context);
      var $stickyFooterLinks = $('a', $stickyFooter);
      var $myFeedText = $('.sticky-footer__my-feed-link', $stickyFooter);
      var $footer = $('.page-footer', context);
      var site = site || {};
      var signedIn = false;
      if (typeof site.userInfoCookie != 'undefined') {
        signedIn = parseInt(site.userInfoCookie.getValue('signed_in'), 10);
      }

      function _scrollToFooter() {
        $(window).scrollTo($footer);
      }

      function _populateMyFeedCount() {
        // @TODO: hook up properly
        var itemCount = 6;
        if (itemCount) {
          $myFeedText.append('<span class="item-count"> (' + itemCount + ')</span>');
        }
      }

      $stickyFooterLinks.on('click', function(event) {
        if ($('.sticky-footer__help-link', this).length) {
          event.preventDefault();
          _scrollToFooter();
        }
      });

      _populateMyFeedCount();
    }
  };

  /**
   * confirm wishlist addition
   */
  Drupal.behaviors.ELBMobile_wishlistConfirm = {
    attach: function(context, settings) {
      if (typeof site.wishlistConfirm != 'undefined') {
        site.wishlistConfirm.init();
      }
    }
  };

  /**
   * estee edit moodboard slideshow overlay launcher
   */
  Drupal.behaviors.mb_slideshow = {
    attach: function(context, settings) {
      // share link vars
      var url = document.documentURI;
      var title = document.title;

      var twitter_url = 'http://twitter.com/intent/tweet?url=' + encodeURI(url) + '&amp;text=' + encodeURI(title) + '&amp;via=EsteeLauder';
      $('.mb-slide-share__link.twitter').attr('href', twitter_url);

      var facebook_url = 'http://www.facebook.com/sharer.php?u=' + encodeURI(url) + '&amp;t=' + encodeURI(title);
      $('.mb-slide-share__link.facebook').attr('href', facebook_url);

      // @todo debug this is grabbing the first image/first slide only
      var img = $('.mb-slideshow__slide img').attr('src');
      var pinterest_url = 'http://pinterest.com/pin/create/button/?url=' + encodeURI(url) + '&amp;media=' + encodeURI(window.location.protocol) + '//' + encodeURI(window.location.host) + img + '&amp;description=' + encodeURI(title);
      $('.mb-slide-share__link.pinterest').attr('href', pinterest_url);

      // // apply flexslider to the main node for previewing purposes
      // if( $('.formatter-mb-slideshow .flexslider').length > 0 ){
      //   $('.formatter-mb-slideshow .flexslider').flexslider({
      //     animation: "fade",
      //     slideshow: false,
      //     controlNav: false
      //   });
      // }
    } // attach
  };

  /**
   * youtube video overlay launcher
   */
  Drupal.behaviors.ELBMobileyoutube = {
    attach: function(context, settings) {
    // send mobile to a different place
      var $isEsteeEdit = $('.brand-estee_edit', context);
      //do nothing for estee edit video overlays on mobile
      if (!$isEsteeEdit) {
        $('a.video-launcher').each(function() {
          var lnk = this.href;
          this.href = lnk.replace('http://www.youtube.com/embed/', 'http://www.youtube.com/watch?v=');
        });
      }
    } // attach
  };

  /**
   * youtube video overlay launcher
   */
  Drupal.behaviors.ELBMobileColorpox = {
    attach: function(context, settings) {
      $(document).on('cbox_complete', function() {
        console.log('cbox complete');
      });

      $(window).on('resize', _.debounce(function() {
        $.colorbox.resize({width: '100%', height: '100%'});
        $(window).trigger('mobile_colorbox_resize');
      }, 100));
    }// attach
  };

  Drupal.behaviors.video_youtube = {
    attach: function(context, settings) {
      $('.video-launcher-mobile').colorbox({iframe: true, width: '100%', height: '100%', className: 'colorbox__youtube'});
      $('.video-launcher-mobile').each(function(e) {
        var youtube_src = $(this).attr('href');
        youtube_src += '?autoplay=1';
        $(this).attr('href', youtube_src);
      });

      // deeplink to autoplay video
      var isAutoPlay = window.location.hash.substr(1);
      if (isAutoPlay === 'video-autoplay') {
        $('.hero-tout-mobile:not(.cancel-autoplay)').each(function() {
          $(this).find('.video-launcher-mobile').trigger('click');
        });
      }
    }// attach
  };
  /**
   * iframe boutique mobile alternative
   */
  Drupal.behaviors.iframe_boutique_mobile_alternative = {
    attach: function(context, settings) {
      // parse URL to load boutique
      // uses Purl.js
      var url_boutique = $.purl_url();
      if (url_boutique.fsegment(1) === 'boutique') {
        window.location = $('[data-boutique-id="' + url_boutique.fsegment(2) + '"]').attr('data-boutique-url');
      }
    }// attach
  };
  /**
   * Mobile specific search block interface behaviors})(jQuery);})(jQuery);
   */
  Drupal.behaviors.ELBPC_searchBlock = {
    attach: function(context, settings) {
      var $header = $('.page-header'),
          $block = $('.el-mobile-search-block', context),
          $dimOverlay = $('<div class="page-header__dim-overlay"></div>'),
          $siteContainer = $('.page-main, .pg_wrapper', context),
          alternate_color = $('.perlgem-mobile-search-block', $block).attr('data-alt-color') || '',
          sdSearchEnabled = $('.js-sd-search-gnav-input-field, .js-sd-discovery-gnav', $header).length > 0;

      if (sdSearchEnabled) {
        return;
      }

      if (alternate_color !== '') {
        $header.addClass(alternate_color);
      }
      // Close search when we click away
      $('html').on('click', function() {
        // if search is open, close it
        if (Drupal.ELB.ui.search_open) {
          $(document).trigger('searchClose');
        }
      });
      // Prevent header clicks from propagating to html tag
      $header.on('click', function(event) {
        event.stopPropagation();
      });
      // Search toggle on button click
      $header.on('click', '.page-utilities__search-button', function(event) {
        event.preventDefault();
        if (Drupal.ELB.ui.search_open) {
          // close if open
          $(document).trigger('searchClose');
        } else {
          // open if closed
          $(document).trigger('searchOpen');
        }
      });
      /**
       * React to searchOpen event
       */
      $(document).on('searchOpen', function(event) {
        Drupal.ELB.ui.search_open = true;
        // close all open nav
        $(document).trigger('navClose');
        // add custom search class onto header, with alt state
        $header.addClass(Drupal.ELB.ui.search_class);
        $('.page-utilities__search-button').addClass('active');
        // endeca search may happen here
        //remove input from search, reset typeahead
        $('input#search').val('');
        $('#typeahead-wrapper').hide();
        $block.show();
        $dimOverlay.prependTo($siteContainer);
        if (alternate_color !== '') {
          $dimOverlay.addClass(alternate_color);
        }
        $('#search').focus();
      });
      /**
       * React to searchClose event
       */
      $(document).on('searchClose', function(event) {
        Drupal.ELB.ui.search_open = false;
        // remove active header class
        $header.removeClass(Drupal.ELB.ui.search_class);
        $('.page-utilities__search-button').removeClass('active');
        $block.hide();
        $dimOverlay.remove();
      });
      // submit using our faux button
      $('.el-search-block__btn-submit').click(function(e) {
        e.preventDefault();
        $('#perlgem-search-form').submit();
      });
      $('.el-mobile-search-close').click(function(e) {
        e.preventDefault();
        $(document).trigger('searchClose');
      });
    } // attach
  };
})(jQuery);
